<template>
    <pagination title="Nominal Codes" :details-open="viewUser" :response="response" :entities="entities" :filterAction="filterAction" header-colour-class="bg-transparent" :withAltViewIcon="true" @altViewClicked="altViewClicked" :withEditIcon="true" @editClicked="editClicked" :editing="editing" @saveClicked="saveClicked" :withDeleteIcon="true" @deleteClicked="deleteClicked" activeClass="bg-v3-gray-50 bg-opacity-20">
        <template v-slot:title>
            <div class="flex justify-between items-center w-full">
                <span>Sage Nominal Codes</span>
                <slot name="title-buttons"></slot>
            </div>
        </template>

        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 w-6"></th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Nominal Code</th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold">Description</th>

        </template>
        <template v-slot:items>
            <tr v-for="entity in entities">
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6"><input type="checkbox" :value="entity.entity.properties.get('id')" v-model="selectedIds"/></td>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    <property-or-field
                    :properties="entity.entity.properties"
                    property-key="nominal_code"
                    :action="editing?entity.entity.actions.filter(action => action.name === 'update-sage-nominal-code').first(): null"
                    fieldId="nominal_code"
                    :showLabel="false"
                    v-model="formAction[entity.entity.properties.get('id')]['nominal_code']"
                    @input="changed($event, entity.entity.properties.get('id'))"
                    :scope="(entity.entity.properties.get('id')).toString()"
                    ></property-or-field>
                    <div v-show="errors.has(entity.entity.properties.get('id')+ '.' +'nominal_code')" class="text-red-600">{{ errors.first(entity.entity.properties.get('id')+ '.' +'nominal_code') }}
                    </div>
                </td>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    <property-or-field
                        :properties="entity.entity.properties"
                        property-key="description"
                        :action="editing?entity.entity.actions.filter(action => action.name === 'update-sage-nominal-code').first(): null"
                        fieldId="description"
                        :showLabel="false"
                        v-model="formAction[entity.entity.properties.get('id')]['description']"
                        @input="changed($event, entity.entity.properties.get('id'))"
                        :scope="(entity.entity.properties.get('id')).toString()"
                    ></property-or-field>
                    <div v-show="errors.has(entity.entity.properties.get('id')+ '.' +'description')" class="text-red-600">{{ errors.first(entity.entity.properties.get('id')+ '.' +'description') }}
                    </div>
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import Siren from "super-siren";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";
let config = {
    rounding: Math.floor
}
dayjs.extend(duration);
dayjs.extend(relativeTime, config);
export default {
  name: 'SageNominalCodePagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false,
          editing:false,
          selectedIds: [],
          editingIds: [],
          deleting: false
      }
    },
    components: {
        PropertyOrField,
        DownloadPdfIconWithLink, FlagOrderAction, StatusBadge, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        },
        formAction(){
            let payload = []
            this.entities.forEach(entity => {
                let action = entity.entity.actions.filter((action, index) => action.name === 'update-sage-nominal-code').first();
                payload[entity.entity.properties.get('id')] = [];

                payload[entity.entity.properties.get('id')]['description'] = action.fields.filter(action => action.name === 'description').first().value;
                payload[entity.entity.properties.get('id')]['nominal_code'] = action.fields.filter(action => action.name === 'nominal_code').first().value;
            });
            return payload;
        }
    },
  props: {
    entities: {},
      response: {
        type: Object,
          default: null
      }
  },
    mounted() {
    },
    methods:{
      setDetailsOpen(entity){
          this.viewUser = true;
      },
      openDetails(entity){
          this.$emit('setLoading');
          this.details = false;
          this.viewUser = true;
          this.getDetails(entity);
      },
        getDetails(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            console.log(selfLink);
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function(){

            });
        },
        altViewClicked(){
            this.$emit('altViewClicked');
            this.viewUser = false;
        },
        editClicked(){
          this.editing = true;
        },
        async saveClicked(){
          if(!this.saving) {
              this.saving = true;
              this.$setLaravelValidationErrorsFromResponse("");
              let hasErrors = false
              await this.editingIds.forEach(async id => {
                  let entitiesToUpdate = this.entities.filter(entity => entity.entity.properties.get('id') === id);
                  await entitiesToUpdate.forEach(async entity => {
                      let action = entity.entity.actions.filter(action => action.name === 'update-sage-nominal-code').first();
                      if (action) {
                          await action.perform({
                              description: this.formAction[id]['description'],
                              nominal_code: this.formAction[id]['nominal_code'],
                          }).then(async res => {
                              if (!hasErrors) {
                                  this.saving = false;
                                  this.editing = false;
                                  this.$emit('reloadItems');
                              }
                          }).catch(async error => {
                              hasErrors = true;
                              if (error.status === 422) {
                                  this.$setLaravelValidationErrorsFromResponse(
                                      JSON.parse(error.response.text), id.toString()
                                  );
                              }
                              console.log('error');
                              this.saving = false;
                          });
                      }
                  })

              });
          }

        },
        changed(event, id){
          if(!this.editingIds.includes(id)){
              this.editingIds.push(id);
          }
        },
        async deleteClicked(){
            if(!this.deleting)
                this.deleting = true;
            let hasErrors = false
            if(this.selectedIds.length === 0){
                alert('Please select sage tax codes to delete.');
                return;
            }
            await this.selectedIds.forEach(id => {
                let entitiesToUpdate = this.entities.filter(entity => entity.entity.properties.get('id') === id);
                entitiesToUpdate.forEach(entity => {
                    let action = entity.entity.actions.filter(action => action.name === 'delete-sage-nominal-code').first();
                    if(action) {
                        action.perform().then(async res => {
                            if (!hasErrors) {
                                this.saving = false;
                                this.deleting = false;
                                this.$emit('reloadItems');
                            }
                        }).catch(async error => {
                            hasErrors = true;
                            this.deleting = false;
                            console.log('error');
                        });
                    }
                })

            });
        },
    }
}
</script>
